<template>
  <div class="col col-xl-8">
    <div class="text-center">
      <h1 class="display-4">ADMX Sets</h1>
      <p>Individual sets of ADMX files available</p>
    </div>

    <div id="app">
      <div v-if="!model.setGroups.length">Loading..</div>

      <div
        v-for="group in sortArrayByOrder(model.setGroups)"
        :key="group.id"
        class="mb-4"
      >
        <h4>{{ group.displayName }}</h4>
        <small>{{ group.description }}</small>

        <table class="table table-sm">
          <thead>
            <tr>
              <th class="col-5">Name</th>
              <th class="col-1"></th>
              <th class="col-2">Added</th>
              <th class="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="set in group.sets" :key="set.id">
              <td>{{ set.displayName }}</td>
              <td class="text-nowrap">
                <router-link
                  :to="{
                    name: 'admx-set-view',
                    params: {
                      setId: set.id,
                      policyClass: 'Machine',
                      languageCode: getLanguageCode(set),
                    },
                  }"
                  title="Open machine policies"
                  ><admx-icon-policy-machine
                /></router-link>
                <router-link
                  :to="{
                    name: 'admx-set-view',
                    params: {
                      setId: set.id,
                      policyClass: 'User',
                      languageCode: getLanguageCode(set),
                    },
                  }"
                  title="Open user policies"
                  class="ml-1"
                >
                  <admx-icon-policy-user />
                </router-link>

                <router-link
                  :to="{
                    name: 'admx-set-registry',
                    params: {
                      setId: set.id,
                      policyClass: 'Machine',
                      languageCode: getLanguageCode(set),
                    },
                  }"
                  title="Open machine registry overview"
                  class="ml-1"
                >
                  <admx-icon-registry-machine />
                </router-link>

                <router-link
                  :to="{
                    name: 'admx-set-registry',
                    params: {
                      setId: set.id,
                      policyClass: 'User',
                      languageCode: getLanguageCode(set),
                    },
                  }"
                  title="Open user registry overview"
                  class="ml-1"
                >
                  <admx-icon-registry-user />
                </router-link>

                <router-link
                  :to="{
                    name: 'admx-set-files',
                    params: {
                      setId: set.id,
                    },
                  }"
                  title="View files"
                  class="ml-1"
                >
                  <admx-icon-files />
                </router-link>
              </td>
              <td>{{ set.published | formatDate("YYYY-MM-DD") }}</td>
              <td class="d-flex text-nowrap">
                <div class="mr-auto">
                  {{ set.admxFiles }} ADMX files;
                  <span v-bind:title="set.uniqueLanguageCodes | join"
                    >{{ set.uniqueLanguageCodes.length }} languages</span
                  >
                </div>

                <div v-if="set.canDownloadFromThisSite">
                  <router-link
                    :to="{
                      name: 'admx-set-download',
                      params: {
                        setId: set.id,
                      },
                    }"
                    title="Download a .zip with the ADMX files"
                    class="ml-1"
                  >
                    <b-icon-download />
                  </router-link>
                </div>

                <div v-if="set.publicDownloadLink">
                  <a
                    v-bind:href="set.publicDownloadLink"
                    class="ml-2"
                    title="Go to the source of the ADMX files"
                  >
                    <b-icon-link45deg />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as UserSettings from "../user-settings";
import * as Utility from "../utilities";

export default {
  async created() {
    let data = await this.$root.$api.getSets();

    this.model = data;
  },
  methods: {
    getLanguageCode(set) {
      const userCc = UserSettings.getCountryCode();

      return Utility.getLanguageCodeByCountryCode(
        set.uniqueLanguageCodes,
        userCc,
        set.fallbackLanguageCode
      );
    },
    sortArrayByOrder(array) {
      return array.slice().sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    viewLink(setId, policyClass, languageCode) {
      return "/admx/view/" + setId + "/" + policyClass + "/" + languageCode;
    },
    registryLink(setId, policyClass, languageCode) {
      return "/admx/registry/" + setId + "/" + policyClass + "/" + languageCode;
    },
  },
  data() {
    return {
      model: { setGroups: [] },
    };
  },
};
</script>
