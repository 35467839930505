var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col col-xl-8"},[_vm._m(0),_c('div',{attrs:{"id":"app"}},[(!_vm.model.setGroups.length)?_c('div',[_vm._v("Loading..")]):_vm._e(),_vm._l((_vm.sortArrayByOrder(_vm.model.setGroups)),function(group){return _c('div',{key:group.id,staticClass:"mb-4"},[_c('h4',[_vm._v(_vm._s(group.displayName))]),_c('small',[_vm._v(_vm._s(group.description))]),_c('table',{staticClass:"table table-sm"},[_vm._m(1,true),_c('tbody',_vm._l((group.sets),function(set){return _c('tr',{key:set.id},[_c('td',[_vm._v(_vm._s(set.displayName))]),_c('td',{staticClass:"text-nowrap"},[_c('router-link',{attrs:{"to":{
                  name: 'admx-set-view',
                  params: {
                    setId: set.id,
                    policyClass: 'Machine',
                    languageCode: _vm.getLanguageCode(set),
                  },
                },"title":"Open machine policies"}},[_c('admx-icon-policy-machine')],1),_c('router-link',{staticClass:"ml-1",attrs:{"to":{
                  name: 'admx-set-view',
                  params: {
                    setId: set.id,
                    policyClass: 'User',
                    languageCode: _vm.getLanguageCode(set),
                  },
                },"title":"Open user policies"}},[_c('admx-icon-policy-user')],1),_c('router-link',{staticClass:"ml-1",attrs:{"to":{
                  name: 'admx-set-registry',
                  params: {
                    setId: set.id,
                    policyClass: 'Machine',
                    languageCode: _vm.getLanguageCode(set),
                  },
                },"title":"Open machine registry overview"}},[_c('admx-icon-registry-machine')],1),_c('router-link',{staticClass:"ml-1",attrs:{"to":{
                  name: 'admx-set-registry',
                  params: {
                    setId: set.id,
                    policyClass: 'User',
                    languageCode: _vm.getLanguageCode(set),
                  },
                },"title":"Open user registry overview"}},[_c('admx-icon-registry-user')],1),_c('router-link',{staticClass:"ml-1",attrs:{"to":{
                  name: 'admx-set-files',
                  params: {
                    setId: set.id,
                  },
                },"title":"View files"}},[_c('admx-icon-files')],1)],1),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(set.published,"YYYY-MM-DD")))]),_c('td',{staticClass:"d-flex text-nowrap"},[_c('div',{staticClass:"mr-auto"},[_vm._v(" "+_vm._s(set.admxFiles)+" ADMX files; "),_c('span',{attrs:{"title":_vm._f("join")(set.uniqueLanguageCodes)}},[_vm._v(_vm._s(set.uniqueLanguageCodes.length)+" languages")])]),(set.canDownloadFromThisSite)?_c('div',[_c('router-link',{staticClass:"ml-1",attrs:{"to":{
                    name: 'admx-set-download',
                    params: {
                      setId: set.id,
                    },
                  },"title":"Download a .zip with the ADMX files"}},[_c('b-icon-download')],1)],1):_vm._e(),(set.publicDownloadLink)?_c('div',[_c('a',{staticClass:"ml-2",attrs:{"href":set.publicDownloadLink,"title":"Go to the source of the ADMX files"}},[_c('b-icon-link45deg')],1)]):_vm._e()])])}),0)])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"display-4"},[_vm._v("ADMX Sets")]),_c('p',[_vm._v("Individual sets of ADMX files available")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-5"},[_vm._v("Name")]),_c('th',{staticClass:"col-1"}),_c('th',{staticClass:"col-2"},[_vm._v("Added")]),_c('th',{staticClass:"col"})])])}]

export { render, staticRenderFns }